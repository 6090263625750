import Container_DBDefaultAreaSkinComponent from '../components/Container/viewer/skinComps/DBDefaultAreaSkin/DBDefaultAreaSkin.skin';


const Container_DBDefaultAreaSkin = {
  component: Container_DBDefaultAreaSkinComponent
};


export const components = {
  ['Container_DBDefaultAreaSkin']: Container_DBDefaultAreaSkin
};

